<template>
  <div>
    <b-card title="Oluştur">
      <validation-observer ref="simpleRules">
        <worker-definition-form />
      </validation-observer>
    </b-card>
    <div class="text-right">
      <b-button
        variant="primary"
        @click="saveData"
      >
        Kaydet
      </b-button>
    </div>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import WorkerDefinitionForm from '@/views/Service_work_orders/Defines/Workers/WorkerForm.vue'

export default {
  name: 'Add',
  components: {
    WorkerDefinitionForm,
    ValidationObserver,
    BCard,
    BButton,
  },
  computed: {
    dataItem() {
      return this.$store.getters['swoWorkers/dataItem']
    },
    saveStatus() {
      return this.$store.getters['swoWorkers/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          title: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.$router.push('/service/work-orders/defines/workers')
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.$store.commit('swoWorkers/RESET_DATA_ITEM')
  },
  methods: {
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('swoWorkers/saveData', this.dataItem)
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
